import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { IBreadCrumb } from './zonar-ui-breadcrumb.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './zonar-ui-breadcrumb.component.html',
  styleUrls: ['./zonar-ui-breadcrumb.component.scss'],
})
export class ZonarUiBreadcrumbComponent implements OnChanges {
  @Input() breadcrumbList: IBreadCrumb[] = [];
  @Input() home!: IBreadCrumb;

  parentSubscription = new Subscription();

  constructor(
    private router: Router,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['breadcrumbList'] && this.home) {
      this.breadcrumbList = [this.home, ...changes['breadcrumbList'].currentValue];
    }
  }

  onHomePage() {
    if (!this.breadcrumbList || !this.breadcrumbList.length || this.breadcrumbList.length > 1) {
      return;
    }

    const currentUrl = this.router.url.split('?')[0];
    this.router.navigateByUrl('/refresh-component', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }
}
