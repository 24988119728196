import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ChipChanged, ChipDataDialog, RowModel } from 'src/app/core/models/shared.model';

@Component({
  selector: 'app-role-edit',
  templateUrl: './role-edit.component.html',
  styleUrls: ['./role-edit.component.scss']
})
export class RoleEditComponent implements OnInit {
  originRole: RowModel[] = [];
  roleEmitted!: ChipChanged;
  hasDataChanged = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ChipDataDialog,
    public dialogRef: MatDialogRef<RoleEditComponent>,
  ) { }

  ngOnInit(): void {
    this.originRole = [...this.data.chipList];
  }

  onCancel() {
    this.dialogRef.close(
      {
        addChipList: [],
        removeChipList: [],
        resultChipList: this.originRole
      }
    );
  }

  onSave() {
    this.dialogRef.close(this.roleEmitted);
  }

  roleChanged(roleEmitted: ChipChanged) {
    this.hasDataChanged = true;
    this.roleEmitted = roleEmitted;
  }

}
