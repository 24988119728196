export interface AppBaseUrl {
  [key: string]: EnvBaseUrl;
}

export interface EnvBaseUrl {
  [key: string]: AppConfig;
}

export interface AppConfig {
  route: string;
  appId: string;
}

export const ADMIN_PLUS_BASE_URL: AppBaseUrl = {
  NA: {
    development: {
      route: 'https://adminplus.dev.zonarsystems.net',
      appId: 'b9203a2f-1a72-42ff-bcba-b7c017a1d49d'
    },
    qa: {
      route: 'https://adminplus.qa.dev.zonarsystems.net',
      appId: '7bd2fe46-cec2-435a-a42d-dff34669605e'
    },
    staging: {
      route: 'https://adminplus.staging.production.zonarsystems.net',
      appId: '5e6403e5-b8db-4637-ab3a-3bec41efd725'
    },
    production: {
      route: 'https://adminplus.production.zonarsystems.net',
      appId: '5e6403e5-b8db-4637-ab3a-3bec41efd725'
    },
  }
};

export const CORE_CONFIGURATION_BASE_URL: AppBaseUrl = {
  NA: {
    development: {
      route: 'https://core-admin.dev.zonarsystems.net',
      appId: 'd9178149-a27f-43a1-ac38-a4b74503d147'
    },
    qa: {
      route: 'https://core-admin.qa.zonarsystems.net',
      appId: 'bdeede23-3f43-489c-bcac-ae101dc0a8a2'
    },
    production: {
      route: 'https://core-admin.production.zonarsystems.net',
      appId: '9a624e20-3323-4de4-be8a-c727a130de1c'
    },
  }
};
