<div fxFlex fxLayout="row" fxLayoutAlign="center center">
  <mat-card class="page-container">
    <div class="page-content" fxLayout="column">
      <mat-card-title>
        <mat-icon>error_outline</mat-icon>
      </mat-card-title>

      <mat-card-content fxFlex fxLayout="column" class="card-content">
        <span class="title">404 - Page Not Found</span>
        <span class="message">Oops, something went wrong. We can't seem to find the page you are looking for.</span>
      </mat-card-content>
    </div>
  </mat-card>
</div>
