import { Injectable } from '@angular/core';
import { NavigationBehaviorOptions, NavigationEnd, Router } from '@angular/router';

@Injectable({providedIn: 'root'})
export class PreviousRouteService {
  private previousUrl: string = '';
  private currentUrl: string = '';
  constructor(private router: Router) {
    this.currentUrl = this.router.url;
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      }
    });
  }

  public getPreviousUrl() {
    return this.previousUrl;
  }

  goBack(extras?: NavigationBehaviorOptions): void {
    // previousUrl should never be falsy.
    if (this.previousUrl) {
      this.router.navigateByUrl(this.previousUrl, extras);
    } else {
      this.router.navigateByUrl('/');
    }
  }

}
