import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ComponentViewMode } from '@app/core/consts/app.const';
import { errorApiCode } from '@app/core/consts/common.const';
import { removeSpaces } from '@app/core/utils';
import { editApplicationPermission, createApplicationPermission, resetEditApplicationPermissionState } from '@app/shared/data-store/applications/application-data-store.actions';
import { selectCreateEditPermissionError } from '@app/shared/data-store/applications/application-data-store.selects';
import { Store } from '@ngrx/store';
import { isBoolean, isEqual } from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-create-edit-permission-dialog',
  templateUrl: './create-edit-permission-dialog.component.html',
  styleUrls: ['./create-edit-permission-dialog.component.scss']
})
export class CreateEditDialogComponent implements OnInit, OnDestroy {

  private onDestroy$ = new Subject<void>();

  componentViewMode = ComponentViewMode;
  permissionFormGroup = new FormGroup({
    permissionName: new FormControl('')
  });
  applicationId: string | undefined;
  permId: string | undefined;
  dialogMode: ComponentViewMode | undefined;
  currentPermission: any = '';
  initPermission: string | undefined;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CreateEditDialogComponent>,
    public store: Store<any>,
  ) { }

  ngOnInit(): void {
    this.processInjectedData();
  }

  onCancel() {
    this.dialogRef.close();
  }

  onSave() {
    this.processApiCall();
  }

  processInjectedData() {
    this.applicationId = this.data?.applicationId;
    this.permId = this.data?.permId;
    this.dialogMode =  this.data.permId ? ComponentViewMode.EDIT : ComponentViewMode.CREATE;

    if (this.dialogMode === ComponentViewMode.EDIT) {
      this.initPermission = this.data?.initValue;
      this.currentPermission = this.initPermission;
      this.permissionFormGroup.controls.permissionName.setValue(this.data?.initValue);
    } else {
      this.initPermission = '';
    }
    this.permissionFormGroup.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(formValue => {
      this.currentPermission = formValue.permissionName;
    });

    this.registerAfterCallApiStatus();
  }

  registerAfterCallApiStatus() {
    this.store.select(selectCreateEditPermissionError).pipe(takeUntil(this.onDestroy$))
      .subscribe(({ status, error }) => {
        if (isBoolean(status) && !status) {
          if (error) {
            if (error.msgId === errorApiCode.CONFLICT_DISCOVERED) {
              this.permissionFormGroup.get('permissionName')?.setErrors({
                duplicated: true
              });
            }
          } else {
            this.dialogRef.close({reloadTable: true});
          }
        }
      });
  }

  processApiCall() {
    const permissionName = removeSpaces(this.permissionFormGroup.controls.permissionName.value as string);
    permissionName?.trim();
    if (!!permissionName.length) {
      switch (this.data?.componentViewMode) {
        case ComponentViewMode.EDIT:
          this.store.dispatch(editApplicationPermission({ payload: { appId: this.data?.appId, permId: this.data?.permId, permissionName } }));
          break;
        case ComponentViewMode.CREATE:
          this.store.dispatch(createApplicationPermission({ payload: { appId: this.data?.appId, permissionName } }));
          break;
      }
    }
  }

  isSaveButtonDisabled() {
    return this.permissionFormGroup.status !== 'VALID'
      || (!this.hasPermissionChanged());
  }

  hasPermissionChanged() {
    return !isEqual(this.initPermission, this.currentPermission);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.store.dispatch(resetEditApplicationPermissionState());
  }

}
