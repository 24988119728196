<div fxLayout="column" fxLayoutAlign="space-between end">
  <app-chip-select fxFill [allChips]="data.allChips" [chipList]="data.chipList"
    (chipChanged)="roleChanged($event)" [rowData]="data.rowData"></app-chip-select>
  <div class="button-container" fxLayout="row" fxLayoutAlign="end end">
    <button mat-stroked-button type="button" (click)="onCancel()" fxLayoutAlign="center center">
      Cancel</button>
    <button [disabled]="!hasDataChanged" mat-flat-button type="submit" class="button-ml action-button" fxLayoutAlign="center center"
      (click)="onSave()">Save</button>
  </div>
</div>
