<div class="create-edit-permission-container"
  fxLayout="column" fxLayoutAlign="space-between start">
  <h1 fxFlex="5" class="page-title">{{dialogMode === componentViewMode.EDIT ? 'Edit Permission' : 'Create Permission'}}</h1>
  <div *ngIf="dialogMode === componentViewMode.EDIT" class="warning-container">
    <mat-icon fxFlex="column wrap inline" fxLayoutAlign="center center" class="warning-icon"> info_outline</mat-icon>
    <span class="warning-message">
      Changing a permission value might break the application. Please verify with your team before changing the permission values.
    </span>
  </div>
  <form [formGroup]="permissionFormGroup" fxLayout="column" class="form-container" fxLayoutGap="0.75rem">
    <mat-form-field appearance="outline">
      <input type="text"
        placeholder="Enter new permission name"
        aria-label="permission name"
        matInput
        formControlName="permissionName"
      >
      <mat-error *ngIf="permissionFormGroup.controls.permissionName.hasError('duplicated')">
        Permission name is already exist.
      </mat-error>
    </mat-form-field>
  </form>
  <div class="button-container" fxLayout="row" fxLayoutAlign="end end">
    <button mat-raised-button type="button" fxLayoutAlign="center center" (click)="onCancel()">
      Cancel</button>
    <button [disabled]="isSaveButtonDisabled()" mat-flat-button type="submit" fxLayoutAlign="center center" class="button-ml action-button"
      (click)="onSave()">Save</button>
  </div>
</div>
