/* Angular */
import { ApiError, Application, Role } from '@app/core/models/shared.model';
import { createAction, props } from '@ngrx/store';

/* action types */
export enum ActionTypes {
  Init = '[APPLICATION] Init',

  GetApplicationList = '[APPLICATION] GetApplicationList',
  GetApplicationListSuccess = '[APPLICATION] GetApplicationListSuccess',
  GetApplicationById = '[APPLICATION] GetApplicationById',
  GetApplicationByIdSuccess = '[APPLICATION] GetApplicationByIdSuccess',
  GetRoleListByAppId = '[APPLICATION] GetRoleListByAppId',
  GetRoleListByAppIdSuccess = '[APPLICATION] GetRoleListByAppIdSuccess',
  GetPermissionListByAppId = '[APPLICATION] GetPermissionListByAppId',
  GetPermissionListByAppIdSuccess = '[APPLICATION] GetPermissionListByAppIdSuccess',
  GetRoleById = '[APPLICATION] GetRoleById',
  GetRoleByIdSuccess = '[APPLICATION] GetRoleByIdSuccess',
  CreateApplication = '[APPLICATION] CreateApplication',
  CreateApplicationSuccess = '[APPLICATION] CreateApplicationSuccess',
  CreateApplicationFailure = '[APPLICATION] CreateApplicationFailure',
  CreateApplicationPermission = '[APPLICATION] CreateApplicationPermission',
  DeleteApplicationPermission = '[APPLICATION] DeleteApplicationPermission',
  EditApplication = '[APPLICATION] EditApplication',
  EditApplicationSuccess = '[APPLICATION] EditApplicationSuccess',
  EditApplicationFailure = '[APPLICATION] EditApplicationFailure',
  EditApplicationPermission = '[APPLICATION] EditApplicationPermission',
  CreateEditApplicationPermissionSuccess = '[APPLICATION] CreateEditApplicationPermissionSuccess',
  CreateEditApplicationPermissionFailure = '[APPLICATION] CreateEditApplicationPermissionFailure',
  ResetEditApplicationPermissionState = '[APPLICATION] ResetEditApplicationPermissionState',
  ResetApplicationLoadingState = '[APPLICATION] ResetApplicationLoadingState',

  UpdateRolePermissions = '[APPLICATION] UpdateRolePermissions',
  UpdateRolePermissionsSuccess = '[APPLICATION] UpdateRolePermissionsSuccess',

  /* create application's role action types */
  CreateRole = '[APPLICATION] CreateRole',
  CreateRoleSuccess = '[APPLICATION] CreateRoleSuccess',
  CreateRoleFailure = '[APPLICATION] CreateRoleFailure',
  /* edit application's role action types */
  EditRole = '[APPLICATION] EditRole',
  EditRoleCheckDuplicate = '[APPLICATION] EditRoleCheckDuplicate',
  EditRoleSuccess = '[APPLICATION] EditRoleSuccess',
  EditRoleFailure = '[APPLICATION] EditRoleFailure',
  /* general create/edit application's role action types */
  RoleDuplicate = '[APPLICATION] RoleDuplicate',
  ResetRole = '[APPLICATION] ResetRole'
}

/* actions */
export const initApplication = createAction(ActionTypes.Init);
export const getApplicationList = createAction(ActionTypes.GetApplicationList,props<{ payload: any }>());
export const getApplicationListSuccess = createAction(ActionTypes.GetApplicationListSuccess, props<{
  payload: { applicationList: Application[], applicationCount: number } }>());
export const getApplicationById = createAction(ActionTypes.GetApplicationById, props<{ payload: string }>());
export const getApplicationByIdSuccess = createAction(ActionTypes.GetApplicationByIdSuccess, props<{ payload: Application | null }>());
export const getRoleById = createAction(ActionTypes.GetRoleById, props<{ payload: {appId: string, roleId: string} }>());
export const getRoleByIdSuccess = createAction(ActionTypes.GetRoleByIdSuccess, props<{ payload: Role | null }>());
export const getRoleListByAppId = createAction(ActionTypes.GetRoleListByAppId, props<{ payload: { id: string, params: any } }>());
export const getRoleListByAppIdSuccess = createAction(ActionTypes.GetRoleListByAppIdSuccess, props<{ payload: { roles: Role[], totalRoles: number } }>());
export const getPermissionListByAppId = createAction(ActionTypes.GetPermissionListByAppId, props<{ payload: { id: string, params: any } }>());
export const getPermissionListByAppIdSuccess = createAction(ActionTypes.GetPermissionListByAppIdSuccess, props<{ payload: { permissions: string[], totalPermissions: number } }>());

export const createApplication = createAction(ActionTypes.CreateApplication, props<{ payload: {application: Partial<Application>} }>());
export const createApplicationSuccess = createAction(ActionTypes.CreateApplicationSuccess, props<{ payload: {application: Application} }>());
export const createApplicationFailure = createAction(ActionTypes.CreateApplicationFailure, props<{ payload: {error: any} }>());
export const createApplicationPermission = createAction(ActionTypes.CreateApplicationPermission, props<{ payload: {appId: string, permissionName: string} }>());
export const deleteApplicationPermission = createAction(ActionTypes.DeleteApplicationPermission, props<{ payload: {appId: string, permId: string} }>());


export const editApplication = createAction(ActionTypes.EditApplication, props<{ payload: {appId: string, application: Partial<Application>} }>());
export const editApplicationSuccess = createAction(ActionTypes.EditApplicationSuccess);
export const editApplicationFailure = createAction(ActionTypes.EditApplicationFailure, props<{ payload: {error: any} }>());
export const editApplicationPermission = createAction(ActionTypes.EditApplicationPermission, props<{
  payload: {appId: string, permId: string, permissionName: string} }>());
export const createEditApplicationPermissionSuccess = createAction(ActionTypes.CreateEditApplicationPermissionSuccess, props<{ payload: {appId: string} }>());
export const createEditApplicationPermissionFailure = createAction(ActionTypes.CreateEditApplicationPermissionFailure, props<{ payload: {error: ApiError} }>());
export const resetEditApplicationPermissionState = createAction(ActionTypes.ResetEditApplicationPermissionState);
export const resetApplicationLoadingState = createAction(ActionTypes.ResetApplicationLoadingState);

export const updateRolePermissions = createAction(ActionTypes.UpdateRolePermissions, props<{
  payload: { appId: string, roleId: string, permissions: string[] } }>());

export const updateRolePermissionsSuccess = createAction(ActionTypes.UpdateRolePermissionsSuccess, props<{ payload: { appId: string, roleId: string }}>());

/* create application's role actions */
export const createRole = createAction(ActionTypes.CreateRole, props<{ payload: { applicationId: string, createRoleParams: Partial<Role> } }>());
export const createRoleSuccess = createAction(ActionTypes.CreateRoleSuccess, props<{ payload: { role: Role } }>());
export const createRoleFailure = createAction(ActionTypes.CreateRoleFailure, props<{ payload: { error: any } }>());
/* edit application's role actions */
export const editRole = createAction(ActionTypes.EditRole, props<{ payload: { applicationId: string, roleId: string, editRoleParams: Partial<Role> } }>());
export const editRoleCheckDuplicate = createAction(ActionTypes.EditRoleCheckDuplicate, props<{ payload: { applicationId: string, roleId: string, editRoleParams: Partial<Role>, initRole: Partial<Role> } }>());
export const editRoleSuccess = createAction(ActionTypes.EditRoleSuccess, props<{ payload: { role: Role } }>());
export const editRoleFailure = createAction(ActionTypes.EditRoleFailure, props<{ payload: { error: any } }>());
/* general create/edit application's role action types */
export const roleDuplicate = createAction(ActionTypes.RoleDuplicate, props<{ payload: { error: any } }>());
export const resetRole = createAction(ActionTypes.ResetRole);
