import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ChipChanged, RowModel } from 'src/app/core/models/shared.model';

@Component({
  selector: 'app-chip-select',
  templateUrl: './chip-select.component.html',
  styleUrls: ['./chip-select.component.scss']
})
export class ChipSelectComponent implements OnInit {
  @Input() allChips: RowModel[] = [];
  @Input() chipList: RowModel[] = [];
  @Input() rowData: any = {};
  @Output() chipChanged = new EventEmitter<ChipChanged>();
  @ViewChild('chipInput') chipInput!: ElementRef<HTMLInputElement>;

  separatorKeysCodes: number[] = [ENTER, COMMA];
  chipCtrl = new FormControl('');
  filteredChips: Observable<RowModel[]>;
  addChipList: RowModel[] = [];
  removeChipList: RowModel[] = [];
  originChipList: RowModel[] = [];

  constructor() {
    this.filteredChips = this.chipCtrl.valueChanges.pipe(
      startWith(null),
      map((chip: string | null) => (chip ? this._filter(chip) : this.allChips.slice())),
    );
  }

  ngOnInit(): void {
    this.originChipList = [...this.chipList];
    this.filteredAllChips();
  }

  filteredAllChips() {
    this.chipList.forEach(chipInit => {
      const index = this.allChips.findIndex(chip => chip.id === chipInit.id);

      if (index >= 0) {
        this.allChips.splice(index, 1);
      }
    });
  }

  buildChipListChange() {
    this.removeChipList = this.originChipList.filter(chip => !this.chipList.find(item => item.id === chip.id));
    this.addChipList = this.chipList.filter(chip => !this.originChipList.find(item => item.id === chip.id));
  }

  removeChip(chipRemove: RowModel): void {
    const index = this.chipList.findIndex(chip => chip.id === chipRemove.id);

    if (index >= 0) {
      this.chipList.splice(index, 1);
      this.allChips.push(chipRemove);
      this.chipCtrl.setValue(null);
    }
    this.buildChipListChange();
    this.chipChanged.emit({
      addChipList: this.addChipList,
      removeChipList: this.removeChipList,
      resultChipList: this.chipList
    });
  }

  chipSelected(event: any): void {
    this.chipList.push(event.option.value);
    this.chipInput.nativeElement.value = '';
    const index = this.allChips.findIndex(chip => chip.id === event?.option?.value?.id);

    if (index >= 0) {
      this.allChips.splice(index, 1);
    }
    this.chipCtrl.setValue(null);
    this.buildChipListChange();

    this.chipChanged.emit({
      addChipList: this.addChipList,
      removeChipList: this.removeChipList,
      resultChipList: this.chipList
    });
  }

  private _filter(value: string): RowModel[] {
    if (typeof (value) === 'string') {
      const filterValue = value?.toLowerCase();

      return this.allChips.filter(chip => chip?.name?.toLowerCase().includes(filterValue));
    }

    return this.allChips;
  }
}
