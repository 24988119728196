import { Permissions } from '@app/core/consts/permission.const';

export const permissions = {
  defaultRedirectUrl: '/no-permission',
  defaultPermPrefix: 'product_management',
  routes: [
    ['product/', Permissions.READ_PRODUCTS],
    ['product/:id/', Permissions.READ_PRODUCT],
    ['product/:id/edit', Permissions.UPDATE_PRODUCT],
    ['product/:id/new-application', Permissions.CREATE_APPLICATION],
    ['product/:id/application/:id', Permissions.READ_APPLICATION],
    ['product/new', Permissions.CREATE_PRODUCT],

    ['application/', Permissions.READ_APPLICATIONS],
    ['application/:id/', Permissions.READ_APPLICATION],
    ['application/:id/edit', Permissions.UPDATE_APPLICATION],
    ['application/:id/new-product', Permissions.CREATE_PRODUCT],
    ['application/:id/product/:id', Permissions.READ_PRODUCT],
    ['application/new', Permissions.CREATE_APPLICATION],
    ['application/:id/role/:roleId/', Permissions.UPDATE_APPLICATION],

    ['group-policy-template/', Permissions.READ_GROUP_POLICIES],
    ['group-policy-template/:id/', Permissions.READ_GROUP_POLICY],
    ['group-policy-template/:id/edit', Permissions.UPDATE_GROUP_POLICY],
    ['group-policy-template/new', Permissions.CREATE_GROUP_POLICY]
  ],
};

export const appName = 'product-management';
