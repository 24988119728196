
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, switchMap } from 'rxjs/operators';
import { BreadCrumbService } from './core/services/bread-crumb.service';
import { Component, OnInit, Renderer2 } from '@angular/core';
import { SidenavHeaderConfig, SidenavFooterConfig, SidenavMenuConfig } from "@app/shared/config/sidenav.config";
import { PreviousRouteService } from './core/services/previous-route.service';
import { IBreadCrumb } from './modules/zonar-ui-breadcrumb/zonar-ui-breadcrumb.model';
import { environment } from '@environments/environment';
import { SidenavParams } from '@zonar-ui/sidenav/lib/sidenav/interfaces/sidenav-menu.interface';
import { DatadogService } from './core/services/data-dog.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  //mock sidenav
  isSidenavOpen: boolean = true;
  sidenavHeader = SidenavHeaderConfig;
  sidenavMenu = SidenavMenuConfig;
  homePage!: IBreadCrumb;
  breadCrumbList: IBreadCrumb[] = [];
  sidenavFooter = SidenavFooterConfig;
  sidenavParams: SidenavParams = {
    mobileOpened: true,
    expanded: true,
    lockExpansion: false,
    useDefaultHelpItem: false,
    footerOffset: false,
    suppressChangeCompanyDialog: false
  };

  constructor(
    private renderer: Renderer2,
    private previousRouteService: PreviousRouteService,
    private router: Router,
    private route: ActivatedRoute,
    private breadCrumbService: BreadCrumbService,
    private datadog: DatadogService,
  ) {
    this.renderer.addClass(document.body, 'zonar-default-theme');
  }
  ngOnInit(): void {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      switchMap(_ => {
        return this.breadCrumbService.customPipeBreadCrumb(this.route);
      })
    ).subscribe((breadCrumbList) => {
      this.homePage = this.breadCrumbService.getHomePage();
      if (this.homePage) {
        this.breadCrumbList = breadCrumbList.filter((breadcrumb) => breadcrumb.url !== this.homePage.url);
      } else {
        this.breadCrumbList = [];
      }
    });
  }

  // #region Side Nav Functions
  onSidenavToggled(event: boolean) {
    // TODO
  }

  onSideNavMobileMenuButtonToggled(event: boolean) {
    this.sidenavParams.mobileOpened = true;
  }

  setCurrentCompanyByFormSelection(event: any) {
    // TODO
  }
}
