/* state interface */
export interface AppState {
    initialized: boolean;
    loading: boolean | null;
}

/* initial values */
export const initialState: AppState = {
    initialized: false,
    loading: null
};
