import { environment } from "@environments/environment";
import { ADMIN_PLUS_BASE_URL, CORE_CONFIGURATION_BASE_URL } from "./base-urls";

export const SidenavHeaderConfig = {
  displayName: true, // if we want to display name of the user
  avatarImageUrl: null // if we want to show a specific avatar picture e.g. user's avatar
};

export const SidenavMenuConfig: any[] = [
  {
    text: 'Admin+',
    icon: 'home',
    route: '/',
    routerLink: ADMIN_PLUS_BASE_URL[environment.region][environment.authEnv].route,
    appId: ADMIN_PLUS_BASE_URL[environment.region][environment.authEnv].appId,
    children: [],
    isExpanded: false,
    routeLevel: 1,
  },
  {
    text: 'Prod-Conf',
    icon: 'production_quantity_limits',
    appId: environment.auth.applicationId,
    route: '/',
    routeLevel: 1,
    isExpanded: false,
    children: [
      {
        text: 'Product List',
        icon: '',
        route: 'product',
        routerLink: '/product',
        isExpanded: false,
        routeLevel: 2,
        children: [],
      },
      {
        text: 'Application List',
        icon: '',
        route: 'application',
        routerLink: '/application',
        isExpanded: false,
        routeLevel: 2,
        children: [],
      },
      ... (environment.authEnv !== 'production') ? [{
        text: 'Group Policy Template List',
        icon: '',
        route: 'group-policy-template',
        routerLink: '/group-policy-template',
        isExpanded: false,
        routeLevel: 2,
        children: [],
      }] : [],
    ],
  },
  {
    text: 'Core Admin',
    icon: 'home',
    routerLink: CORE_CONFIGURATION_BASE_URL[environment.region][environment.authEnv].route,
    appId: CORE_CONFIGURATION_BASE_URL[environment.region][environment.authEnv].appId,
    routeLevel: 1,
    isExpanded: false,
    route: '/',
    children: [],
  },
];

export const SidenavFooterConfig = {
  items: [
    {
      text: 'Help',
      icon: 'help',
      routerLink: `${environment?.sidenavHelpUrl}`,
    }
  ]
};
