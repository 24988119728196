import { Application, GroupPolicyTemplate } from '@app/core/models/shared.model';

/* state interface */
export interface GroupPolicyState {
  groupPolicyList: GroupPolicyTemplate[] | null,
  groupPolicyCount: number,
  productDetail: {
    groupPolicy: GroupPolicyTemplate | null
    applications: Application[] | null;
    totalApplications: number | null;
  };
}

/* initial values */
export const initialState: GroupPolicyState = {
  groupPolicyList: null,
  groupPolicyCount: 0,
  productDetail: {
    groupPolicy:  null,
    applications:  null,
    totalApplications:  null
  }
};
