export enum Permissions {
  CREATE_PRODUCT = 'create:product',
  READ_PRODUCT = 'read:product',
  READ_PRODUCTS = 'read:products',
  UPDATE_PRODUCT = 'update:product',

  CREATE_APPLICATION = 'create:application',
  READ_APPLICATION = 'read:application',
  READ_APPLICATIONS = 'read:applications',
  UPDATE_APPLICATION = 'update:application',

  CREATE_GROUP_POLICY = 'create:group-policy',
  READ_GROUP_POLICY = 'read:group-policy',
  READ_GROUP_POLICIES = 'read:group-policies',
  UPDATE_GROUP_POLICY = 'update:group-policy',
}
