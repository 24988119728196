export interface ListManagement {
  type?: ListManagementType;
  buttonManagement?: ButtonManagement;
  searchManagement?: SearchManagement;
}

export enum ListManagementType {
  Search = 'Search',
  Button = 'Button'
}

interface ButtonManagement {
  permission?: string;
  actionInput?: any;
  isDisabledButton?: boolean;
  buttonName?: string;
  menuName?: string;
  menuList?: Array<any>;
  controlName?: string;
  hasMenu?: boolean;
}


export interface SearchManagement {
  controlName?: string;
  searchQuery?: string[];
  searchPlaceHolder?: string;
}

export enum TablePropertyParams {
  PER_PAGE = 'per_page',
  PAGE = 'page',
  Q = 'q'
}
