import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ZonarUIMaterialModule } from '@zonar-ui/material';
import { RouterModule } from '@angular/router';
import { ZonarUiBreadcrumbComponent } from './zonar-ui-breadcrumb.component';

@NgModule({
  declarations: [
    ZonarUiBreadcrumbComponent
  ],
  imports: [
    CommonModule,
    ZonarUIMaterialModule,
    RouterModule,
  ],
  exports: [
    ZonarUiBreadcrumbComponent
  ]
})
export class ZonarUiBreadcrumbModule { }
