import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { PageNotFoundComponent } from '@app/shared/components/page-not-found/page-not-found.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'product',
    pathMatch: 'full'
  },
  {
    path: 'product',
    loadChildren: () => import('@app/modules/product/product.module').then(m => m.ProductModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'application',
    loadChildren: () => import('@app/modules/application/application.module').then(m => m.ApplicationModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'group-policy-template',
    loadChildren: () => import('@app/modules/group-policy/group-policy.module').then(m => m.GroupPolicyModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'demo-screen',
    loadChildren: () => import('@app/modules/demo-screen/demo-screen.module').then(m => m.DemoScreenModule),
  },
  {
    path: 'page-not-found',
    component: PageNotFoundComponent
  },
  {
    path: '**',
    redirectTo: 'page-not-found',
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
