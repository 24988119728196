/* Angular */
import { createAction, props } from '@ngrx/store';

/* action types */
export enum ActionTypes {
    InitApp = '[APP] InitApp',
    SomethingElse = '[APP] SomethingElse'
}

/* actions */
export const initApp = createAction(ActionTypes.InitApp);
