/* Angular */
import { GroupPolicyTemplate } from '@app/core/models/shared.model';
import { createAction, props } from '@ngrx/store';

/* action types */
export enum ActionTypes {
  Init = '[GROUP_POLICY] Init',
  GetGroupPolicyList = '[GROUP_POLICY] GetGroupPolicyList',
  GetGroupPolicyListSuccessfully = '[GROUP_POLICY] GetGroupPolicyListSuccessfully',
  GetGroupPolicyListFailure = '[GROUP_POLICY] GetGroupPolicyListFailure',
  GetGroupPolicyById = '[GROUP_POLICY] GetGroupPolicyById',
  GetGroupPolicyByIdSuccessfully = '[GROUP_POLICY] GetGroupPolicyByIdSuccessfully',
}

/* actions */
export const initGroupPolicy = createAction(ActionTypes.Init);
export const getGroupPolicyList = createAction(ActionTypes.GetGroupPolicyList, props<{ payload: { params: any } }>());
export const getGroupPolicyListSuccessfully = createAction(ActionTypes.GetGroupPolicyListSuccessfully,
  props<{ payload: any }>());
export const getGroupPolicyById = createAction(ActionTypes.GetGroupPolicyById,
  props<{ payload: { groupId: string } }>());
export const getGroupPolicyByIdSuccessfully = createAction(ActionTypes.GetGroupPolicyByIdSuccessfully,
  props<{ payload: { groupPolicy: GroupPolicyTemplate } }>());
