<div class="list-management-container" fxLayoutGap="0.5rem" fxLayout="column" fxLayoutAlign="start start">
  <ng-container *ngFor="let buttonItem of buttonList; let i = index">
    <div fxLayout="row" [fxLayoutAlign]="buttonItem.position" class="button-container">
      <button class="default-button action-button" mat-flat-button (click)="onClicked(buttonItem)"
        [disabled]="buttonItem.isDisabledButton">
        {{buttonItem.buttonName | titlecase}}
      </button>
    </div>
  </ng-container>

  <ng-container *ngFor="let searchItem of searchList; let i = index">
    <div class="m-15 search-container">
      <app-search class="app-search" [searchFormControl]="searchesFormGroup.controls[searchItem?.controlName!] "
        placeHolder="{{searchItem.searchPlaceHolder}}">
      </app-search>
    </div>
  </ng-container>

  <app-table-template [dataSource]="dataSource" [columns]="columns" [displayedColumns]="displayedColumns" [rowData]="rowData"></app-table-template>
</div>
