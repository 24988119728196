import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Product } from '@app/core/models/shared.model';
import { environment as env } from '@environments/environment';
import { Observable } from 'rxjs';
import { EntityApiBaseService } from './entity-api-base.service';

@Injectable({
  providedIn: 'root'
})
export class ProductApiService extends EntityApiBaseService {
  private apiUrl = env.coreEntityApiBase.url;
  private PRODUCT_RESOURCE = 'products';
  private APPLICATION_RESOURCE = 'applications';

  getProductList(params: any): Observable<any> {
    const url = `${this.apiUrl}/${this.PRODUCT_RESOURCE}`;
    return this.getData(url, params);
  }

  getProductById(productId: string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/${this.PRODUCT_RESOURCE}/${productId}`;
    return this.getData(url);
  }

  getProductApplications(productId: string, params: any): Observable<any> {
    const url = `${this.apiUrl}/${this.PRODUCT_RESOURCE}/${productId}/${this.APPLICATION_RESOURCE}`;
    return this.getData(url, params);
  }

  createProduct(params: Product): Observable<any> {
    const url = `${this.apiUrl}/${this.PRODUCT_RESOURCE}/`;
    return this.http.post(url, params, { observe: 'response' });
  }

  editProduct(productId: string ,params: Product): Observable<any> {
    const url = `${this.apiUrl}/${this.PRODUCT_RESOURCE}/${productId}`;
    return this.http.patch(url, params, { observe: 'response' });
  }
}
