
export const defaultParamsTable = {
  page: 1,
  per_page: 10
};

export const VALIDATION_PATTERNS = {
  UUID: /\/([0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12})$/
};

export enum errorApiCode {
  CONFLICT_DISCOVERED = '018696dd',
}
