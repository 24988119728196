import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import * as fromAppDataStore from './app-data-store.reducer';
import { initialState } from './app-data-store.interfaces';
import { AppDataStoreEffects } from './app-data-store.effects';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    /* datastores */
    StoreModule.forRoot(
      { app: fromAppDataStore.reducer },
      { initialState: { app: initialState } }
    ),
    StoreDevtoolsModule.instrument({
      maxAge: 50, // Retains last 50 states
    }),
    EffectsModule.forRoot([AppDataStoreEffects])
  ]
})
export class AppDataStoreModule { }
