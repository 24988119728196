/* angular */
import { Application, Product } from '@app/core/models/shared.model';
import { createReducer, on } from '@ngrx/store';

/* services */

/* actions */
import * as ProductDataStoreActions from './product-data-store.actions';
import { initialState } from './product-data-store.interfaces';

/* state key */
export const featureKey = 'product';

export const reducer = createReducer(
  initialState,
  /* reducer functions for actions */
  on(ProductDataStoreActions.initProduct, (state, action) => ({
    ...state,
    loading: true
  })),

  on(ProductDataStoreActions.getProductListSuccess, (state, action) => ({
    ...state,
    loading: false,
    products: action.payload.productList,
    productCount: action.payload.productCount
  })),

  on(ProductDataStoreActions.getProductByIdSuccess, (state, action) => ({
    ...state,
    loading: false,
    productDetail: {
      ...state.productDetail,
      product: action.payload
    }
  })),

  on(ProductDataStoreActions.getProductApplicationsSuccess, (state, action) => ({
    ...state,
    loading: false,
    productDetail: {
      ...state.productDetail,
      applications: action.payload.applications,
      totalApplications: action.payload.totalApplications
    }
  })),

  on(ProductDataStoreActions.createProduct, (state, action) => ({
    ...state,
    productLoadingState: true,
    error: null
  })),

  on(ProductDataStoreActions.createProductSuccess, (state, action) => ({
    ...state,
    productCreated: action.payload.productCreated,
    productLoadingState: false
  })),

  on(ProductDataStoreActions.createProductFailure, (state, action) => ({
    ...state,
    error: action.payload.error,
    productLoadingState: false
  })),

  on(ProductDataStoreActions.editProduct, (state, action) => ({
    ...state,
    productLoadingState: true,
    error: null
  })),

  on(ProductDataStoreActions.editProductSuccess, (state, action) => ({
    ...state,
    productLoadingState: false
  })),

  on(ProductDataStoreActions.editProductFailure, (state, action) => ({
    ...state,
    error: action.payload.error,
    productLoadingState: false
  })),

  on(ProductDataStoreActions.resetProductLoadingState, (state, action) => ({
    ...state,
    productLoadingState: null,
    error: null
  })),
);
