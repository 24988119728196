import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, mergeMap } from 'rxjs/operators';

/* actions */
import { HttpResponse } from '@angular/common/http';
import * as GroupPolicyActions from './group-policy-data-store.actions';
import { GroupPolicyApiService } from '@app/core/services/api/group-policy-api.service';

@Injectable()
export class GroupPolicyDataStoreEffects {
  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private groupPolicyApiService: GroupPolicyApiService
  ) { }

  getGroupPolicyList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GroupPolicyActions.getGroupPolicyList),
      mergeMap(action => {
        return this.groupPolicyApiService.getGroupPolicyList(action.payload.params).pipe(
          mergeMap((res: any) => {
            return [GroupPolicyActions.getGroupPolicyListSuccessfully({
              payload: {
                groupPolicyList: res?.body || [],
                groupPolicyCount: +res.headers.get('x-total-count')
              }
            })];
          })
        );
      })
    )
  );

}
