import { Application, Role } from "@app/core/models/shared.model";

/* state interface */
export interface ApplicationState {
  application: Application | null;
  applicationList: Application[];
  roles: Role[];
  totalRoles: number;
  permissions: string[];
  totalPermissions: number;
  role: Role | null;
  loading: boolean | null;
  applicationCount: number;
  applicationLoadingState: boolean | null;
  createEditPermissionState: boolean | null;
  error: any;

}

/* initial values */
export const initialState: ApplicationState = {
  application: {
    roles: [],
    permissions: [],
    description: '',
    name: '',
    products: [],
    type: ''
  },
  applicationList: [],
  applicationCount: 0,
  roles: [],
  totalRoles: 0,
  permissions: [],
  totalPermissions: 0,
  role: null,
  loading: null,
  applicationLoadingState: null,
  createEditPermissionState: null,
  error: null,
};
