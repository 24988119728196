import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-transfer-dialog',
  templateUrl: './transfer-dialog.component.html',
  styleUrls: ['./transfer-dialog.component.scss']
})
export class TransferDialogComponent {

  updatedSelections: any[] | null = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<TransferDialogComponent>,
  ) { }

  onCancel() {
    this.dialogRef.close();
  }

  onConfirm() {
    this.dialogRef.close(this.updatedSelections);
  }

  updateSelected(event: any[] | null) {
    if (event !== null) {
      this.updatedSelections = [...event];
    } else {
      this.updatedSelections = null;
    }
  }

}
