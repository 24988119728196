<div class="delete-permission-container"
  fxLayout="column" fxLayoutAlign="space-between start">
  <h1 fxFlex="5" class="page-title">Caution!</h1>
  <div class="text-label">{{'Are you sure to delete the ' + data?.permName + '?'}}</div>
  <div class="button-container" fxLayout="row" fxLayoutAlign="end end">
    <button mat-raised-button type="button" fxLayoutAlign="center center" (click)="onCancel()">
      No</button>
    <button mat-flat-button type="submit" fxLayoutAlign="center center" class="button-ml action-button"
      (click)="onOk()">Yes</button>
  </div>
</div>
