<div class="table-template-container">
  <div class="table-container">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" fxLayout="column">
      <ng-container *ngFor="let column of columnsArray; let columnIndex = index" [matColumnDef]="column.columnDef">
        <mat-header-cell *matHeaderCellDef [ngStyle]="column.style">{{column.headerCellDef}}</mat-header-cell>
        <mat-cell *matCellDef="let row" [ngStyle]="column.style">
          <!-- Cell Type: text (default) -->
          <ng-container  *ngIf="column.type === columnCellType.Text || !column.type">
            <span #tooltip [matTooltip]="getCellTooltip(tooltip, column.cell(row))" class="text-overflow" >{{ getCellContext(column.cell(row)) }}</span>
          </ng-container>
          <!-- Cell Type: button -->
          <ng-container  *ngIf="column.type === columnCellType.Button">
            <button class="mat-stroked-button cell-button" (click)="column.buttonOnClick ? column.buttonOnClick(row) : undefined">{{ getCellContext(column.cell(row)) }}</button>
          </ng-container>
          <ng-container  *ngIf="column.type === columnCellType.Row">
            <app-row-by-row [allChips]="row.allItems" [rowList]="row.existedItem" [rowData]="rowData"></app-row-by-row>
          </ng-container>
  
          <ng-container *ngIf="column.type === columnCellType.Link">
            <a color="accent"
               [routerLink]="column.iLink(row)"
               #tooltip [matTooltip]="getCellTooltip(tooltip, column.cell(row))"
               class="text-overflow"
            >{{ getCellContext(column.cell(row)) }}
          </a>
        </ng-container>
        </mat-cell>
      </ng-container>
    
      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns" class="data-row"></mat-row>
    </table>
  </div>
  
  <mat-paginator
    [length]="totalRow"
    [pageSize]="initialPageSize"
    [pageSizeOptions]="[5, 10, 20, 50]"
    aria-label="Select page"
    (page)="onPaginatorChange($event)">
  </mat-paginator>
</div>
