import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { DataStoreModule } from './shared/data-store/data-store.module';
import { SharedModule } from './shared/shared.module';

// Patern Library Modules
import { ZonarOwnerIdInterceptor, ZonarUiAuthModule, ZonarUiPermissionsModule } from '@zonar-ui/auth';
import { SidenavModule as ZonarUiSidenavModule } from '@zonar-ui/sidenav';
import { ZonarUIMaterialModule } from '@zonar-ui/material';

// Local Modules
import { environment } from '@environments/environment';
import { appName, permissions } from '@environments/shared';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexModule } from '@angular/flex-layout';
import { ZonarUiBreadcrumbModule } from './modules/zonar-ui-breadcrumb/zonar-ui-breadcrumb.module';
import { DatadogService } from './core/services/data-dog.service';
import { ZonarUiI18nModule } from '@zonar-ui/i18n';
import { GenericLanguageMap } from './core/consts/app.const';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ZonarUiAuthModule,
    ZonarUiPermissionsModule,
    ZonarUIMaterialModule,
    ZonarUiSidenavModule,
    ZonarUiI18nModule,
    SharedModule,
    CoreModule,
    DataStoreModule,
    HttpClientModule,
    FlexModule,
    ZonarUiBreadcrumbModule,
    AuthModule.forRoot({
      clientId: environment.auth.clientID,
      domain: environment.auth.domain,
      audience: environment.auth.audience,
      redirect_uri: window.location.origin,
      scope: 'openid profile email',
      useRefreshTokens: true,
      max_age: 36000,
      cacheLocation: 'localstorage',
      httpInterceptor: {
        allowedList: [
          // core entity api base urls. these are used by the auth module to make calls to the user-api
          `${environment.coreEntityApiBase.url}/*`,
          `${environment.coreEntityApiBase.url}beta2/*`, // TODO: this is temporary workaround for setting API
        ]
      }
    }),
  ],
  providers: [
    // Auth settings
    { provide: 'applicationId', useValue: environment.auth.applicationId },
    { provide: 'defaultPermPrefix', useValue: permissions.defaultPermPrefix },
    { provide: 'routePermissions', useValue: permissions.routes },
    { provide: 'redirectUrl', useValue: permissions.defaultRedirectUrl },
    { provide: 'logoutReturnToUrl', useValue: environment.appUrl },
    { provide: 'environment', useValue: environment.authEnv },
    { provide: 'appName', useValue: appName },
    { provide: 'region', useValue: environment.region },

    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ZonarOwnerIdInterceptor, multi: true },
    { provide: 'supportedLanguages', useValue: environment.i18n.supportedLanguages },
    { provide: 'defaultLanguage', useValue: environment.i18n.defaultLanguage },
    { provide: 'useBrowserLanguage', useValue: true },
    { provide: 'genericLanguageMap', useValue: GenericLanguageMap },
    { provide: 'DatadogService', useClass: DatadogService},
    ZonarUiAuthModule,
    ZonarUiPermissionsModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
