<mat-form-field class="chip-list" appearance="fill">
  <mat-label>{{rowData?.chipListName}}</mat-label>
  <mat-chip-list #matChipList aria-label="chip selection">
    <mat-chip *ngFor="let chip of chipList" (removed)="removeChip(chip)">
      {{chip.name}}
      <button matChipRemove>
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip>
    <input #chipInput [formControl]="chipCtrl" [matAutocomplete]="auto"
      [matChipInputFor]="matChipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
  </mat-chip-list>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="chipSelected($event)">
    <mat-option *ngFor="let chipFiltered of filteredChips | async" [value]="chipFiltered">
      {{chipFiltered.name}}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>