import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { EntityApiBaseService } from './entity-api-base.service';
import { environment as env } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GroupPolicyApiService extends EntityApiBaseService {
  private apiUrl = env.coreEntityApiBase.url;
  private GROUP_POLICY_RESOURCE = 'group-policy';

  getGroupPolicyList(params?: any): Observable<any> {
    const url = `${this.apiUrl}/${this.GROUP_POLICY_RESOURCE}`;
    return this.getData(url, params);
  }
}
