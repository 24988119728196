export enum ComponentViewMode {
  CREATE = 'create',
  EDIT = 'edit'
}

/* Map Spanish to 'es-ES' and French to 'fr-FR' */
export const GenericLanguageMap = [
  {
    languageAbbreviation: 'es',
    useLanguage: 'es-ES'
  },
  {
    languageAbbreviation: 'fr',
    useLanguage: 'fr-FR'
  }
];