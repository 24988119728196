import { DetailRow } from "./models/detail-list.model";

const uuidRegExp = '\\/([0-9a-f]{8}\\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\\b[0-9a-f]{12})';
const stringRegExp = '(([a-zA-Z])\\w+)';

export function buildKeyValue(data: any, keyEntity: any): DetailRow[] {
  const result: DetailRow[] = [];
  if (Array.isArray(data)) {
    data.forEach(item => {
      Object.keys(keyEntity).forEach(key => {
        result.push(
          { key: toTitleCase(key), value: item[key] }
        );
      });
    });
    return result;
  }
  Object.keys(keyEntity).forEach(key => {
    result.push(
      { key: toTitleCase(key), value: data[key] }
    );
  });
  return result;
}

// parse string of param by URL
export function parseStringByURL(url: string, paramName: string) {
  const paramRegExp = new RegExp(`${paramName}=${stringRegExp}`);
  const match = url.match(paramRegExp);

  return match ? match[1] : null;
}

// parse uuid of param by URL
export function parseUUIDByURL(url: string, paramName: string) {
  const paramRegExp = new RegExp(`${paramName}${uuidRegExp}`);
  const match = url.match(paramRegExp);

  return match ? match[1] : null;
}

export function toTitleCase(str: string): string {
  return str?.split(' ')
    .map(strParts => {
      if (strParts !== '') {
        return strParts[0].toUpperCase() + strParts.substring(1).toLowerCase();
      }
      return '';
    })
    .join(' ');
}

/**
 * Remove multiple spaces with a single space.
 *
 * @param str string
 * @returns string
 */
export const removeSpaces = (str: string): string => {
  const regEx = new RegExp(/\s{2,}/g);
  return str.replace(regEx, ' ').trim();
};
