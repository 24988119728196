import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { ChipChanged, RowModel } from 'src/app/core/models/shared.model';
import { MatDialog } from '@angular/material/dialog';
import { RoleEditComponent } from '../../dialogs/role-edit/role-edit.component';

@Component({
  selector: 'app-row-by-row',
  templateUrl: './row-by-row.component.html',
  styleUrls: ['./row-by-row.component.scss']
})
export class RowByRowComponent {
  @Input() rowList: RowModel[] = [];
  @Input() allChips: RowModel[] = [];
  @Input() rowData: any = {};

  constructor(
    private dialog: MatDialog,
    private cd: ChangeDetectorRef
  ) { }

  onAddOrEdit() {
    this.dialog.open(RoleEditComponent, {
      data: {
        chipList: [...this.rowList],
        allChips: [...this.allChips],
        rowData: this.rowData
      },
      width: '50rem',
      maxHeight: '31.25rem',
      disableClose: true,
      closeOnNavigation: true
    }).afterClosed().subscribe((res: ChipChanged) => {
      if (res) {
        this.rowList = res.resultChipList;
        this.cd.detectChanges();
      }
    });
  }

  identify(item: any){
    return item.id; 
 }
}
