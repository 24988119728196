import { Injectable } from '@angular/core';
import { act, Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, mergeMap } from 'rxjs/operators';

/* actions */
import { HttpResponse } from '@angular/common/http';
import * as ApplicationActions from './application-data-store.actions';
import { ApplicationApiService } from '@app/core/services/api/application-api.service';
import { Application, Role } from '@app/core/models/shared.model';
import { defaultParamsTable, errorApiCode } from '@app/core/consts/common.const';
import { removeSpaces } from '@app/core/utils';

@Injectable()
export class ApplicationDataStoreEffects {
  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private applicationApiService: ApplicationApiService
  ) { }

  getApplications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApplicationActions.getApplicationList),
      mergeMap(action => {
        return this.applicationApiService.getApplicationList(action.payload).pipe(
          mergeMap((res: any) => {
            return [ApplicationActions.getApplicationListSuccess({
              payload: {
                applicationList: res?.body || [],
                applicationCount: +res.headers.get('x-total-count')
              }
            })];
          })
        );
      })
    )
  );

  getApplicationById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApplicationActions.getApplicationById),
      mergeMap((action) => {
        return this.applicationApiService.getApplicationById(action.payload).pipe(
          mergeMap((res: HttpResponse<Application>) => {
            return [ApplicationActions.getApplicationByIdSuccess({
              payload: res?.body || null
            })];
          })
        );
      })
    )
  );

  getRoleById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApplicationActions.getRoleById),
      mergeMap((action) => {
        const { appId, roleId } = action.payload;
        return this.applicationApiService.getRoleById(appId, roleId).pipe(
          mergeMap((res: HttpResponse<Role>) => {
            return [ApplicationActions.getRoleByIdSuccess({
              payload: res?.body || null
            })];
          })
        );
      })
    )
  );

  getRoleListByAppId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApplicationActions.getRoleListByAppId),
      mergeMap((action) => {
        const { id, params } = action.payload;
        return this.applicationApiService.getRoleListByAppId(id, params).pipe(
          mergeMap((res: any) => {
            return [ApplicationActions.getRoleListByAppIdSuccess({
              payload: {
                roles: res?.body || [],
                totalRoles: +res.headers.get('x-total-count')
              }
            })];
          })
        );
      })
    )
  );

  getPermissionListByAppId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApplicationActions.getPermissionListByAppId),
      mergeMap((action) => {
        const { id, params } = action.payload;
        return this.applicationApiService.getPermissionListByAppId(id, params).pipe(
          mergeMap((res: any) => {
            return [ApplicationActions.getPermissionListByAppIdSuccess({
              payload: {
                permissions: res?.body || [],
                totalPermissions: +res.headers.get('x-total-count')
              }
            })];
          })
        );
      })
    )
  );

  createApplication$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApplicationActions.createApplication),
      mergeMap((action) => {
        const { application } = action.payload;
        return this.applicationApiService.createApplication(application).pipe(
          mergeMap((res: any) => {
            return [ApplicationActions.createApplicationSuccess({ payload: { application: res.body } })];
          }),
          catchError((error) => {
            return [ApplicationActions.createApplicationFailure({
              payload: { error }
            })];
          })
        );
      })
    )
  );

  editApplication$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApplicationActions.editApplication),
      mergeMap((action) => {
        const { appId, application } = action.payload;
        return this.applicationApiService.editApplicationWithoutProduct(appId, application).pipe(
          mergeMap(() => {
            return [ApplicationActions.editApplicationSuccess()];
          }),
          catchError((error) => {
            return [ApplicationActions.editApplicationFailure({
              payload: { error }
            })];
          })
        );
      })
    )
  );

  updateRolePermissions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApplicationActions.updateRolePermissions),
      mergeMap((action) => {
        const { appId, roleId, permissions } = action.payload;
        return this.applicationApiService.updateRolePermissions(appId, roleId, {permissions}).pipe(
          mergeMap(() => {
            return [ApplicationActions.updateRolePermissionsSuccess({
              payload:{
                appId,
                roleId
              }
            })];
          })
        );
      })
    )
  );

  updateRolePermissionsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApplicationActions.updateRolePermissionsSuccess),
      mergeMap((action) => {
        const { appId , roleId} = action.payload;
        return [ApplicationActions.getRoleById({
          payload:{
            appId,
            roleId
          }
        })];
      })
    )
  );

  /* create application's role effects */
  createRole$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApplicationActions.createRole),
      mergeMap((action) => {
        const { applicationId, createRoleParams } = action.payload;
        // This handle duplicated role name within application, which is not handled on BE
        const roleName = removeSpaces(createRoleParams.name as string);
        return this.applicationApiService.getAllRolesByAppId(applicationId, {q: roleName, df: 'name'}).pipe(
          mergeMap((roles: Array<Role>) => {
            if ((roles.filter(role => role.name.toLowerCase() === roleName.toLowerCase())).length) {
              return [ApplicationActions.roleDuplicate({
                payload: {
                  error: {
                    msgId: errorApiCode.CONFLICT_DISCOVERED
                  }
                }
              })];
            } else {
              return this.applicationApiService.createRole(applicationId, createRoleParams).pipe(
                mergeMap((res: any) => {
                  return [ApplicationActions.createRoleSuccess({
                    payload: {
                      role: res
                    }
                  })];
                }),
                catchError((error) => {
                  return [ApplicationActions.createRoleFailure({
                    payload: {
                      error: error
                    }
                  })];
                })
              );
            }
          })
        );
      })
    )
  );

  /* edit application's role effects */
  editRoleCheckDuplicate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApplicationActions.editRoleCheckDuplicate),
      mergeMap((action) => {
        const { applicationId, roleId, editRoleParams, initRole } = action.payload;
        // This handle duplicated role name within application, which is not handled on BE
        const roleName = removeSpaces(editRoleParams.name as string);
        const roleNameLower = roleName.toLowerCase();
        if (initRole.name && (initRole.name === editRoleParams.name || initRole.name.toLowerCase() === roleNameLower)) {
          return [ApplicationActions.editRole({
            payload: {
              applicationId,
              roleId,
              editRoleParams
            }
          })];
        } else {
          return this.applicationApiService.getAllRolesByAppId(applicationId, {q: roleName, df: 'name'}).pipe(
            mergeMap((roles: Array<Role>) => {
              if ((roles.filter(role => role.name.toLowerCase() === roleName.toLowerCase())).length) {
                return [ApplicationActions.roleDuplicate({
                  payload: {
                    error: {
                      msgId: errorApiCode.CONFLICT_DISCOVERED
                    }
                  }
                })];
              } else {
                return [ApplicationActions.editRole({
                  payload: {
                    applicationId,
                    roleId,
                    editRoleParams
                  }
                })];
              }
            })
          );
        }
      })
    )
  );

  editRole$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApplicationActions.editRole),
      mergeMap((action) => {
        const { applicationId, roleId, editRoleParams } = action.payload;
        return this.applicationApiService.editRole(applicationId, roleId, editRoleParams).pipe(
          mergeMap((res: any) => {
            return [ApplicationActions.editRoleSuccess({
              payload: {
                role: res
              }
            })];
          }),
          catchError((error) => {
            return [ApplicationActions.editRoleFailure({
              payload: {
                error: error
              }
            })];
          })
        );
      })
    )
  );
  /* end create/edit application's role effect region */

  createEditApplicationPermissionSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApplicationActions.createEditApplicationPermissionSuccess),
      mergeMap((action) => {
        const { appId } = action.payload;
        return [ApplicationActions.getPermissionListByAppId({
          payload: {
            id: appId,
            params: {...defaultParamsTable}
          }
        })];
      })
    )
  );

  createApplicationPermission$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApplicationActions.createApplicationPermission),
      mergeMap((action) => {
        const { appId, permissionName } = action.payload;
        return this.applicationApiService.createApplicationPermission(appId, { permission: permissionName }).pipe(
          mergeMap(() => {
            return [ApplicationActions.createEditApplicationPermissionSuccess({
              payload: {
                appId
              }
            })];
          }),
          catchError((response) => {
            return [ApplicationActions.createEditApplicationPermissionFailure({
              payload: { error: { ...response.error, params: permissionName } }
            })];
          })
        );
      })
    )
  );

  deleteApplicationPermission$ = createEffect(() =>
      this.actions$.pipe(
        ofType(ApplicationActions.deleteApplicationPermission),
        mergeMap((action) => {
          const {appId, permId} = action.payload;
          return this.applicationApiService.deleteApplicationPermission(appId, permId).pipe(
            mergeMap(() => {
              return [ApplicationActions.getPermissionListByAppId({
                payload: {
                  id: appId,
                  params: {...defaultParamsTable}
                }
              })];
            })
          );
        })
      )
  );

  editApplicationPermission$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApplicationActions.editApplicationPermission),
      mergeMap((action) => {
        const { appId, permId, permissionName } = action.payload;
        return this.applicationApiService.editApplicationPermission(appId, permId, { permission: permissionName }).pipe(
          mergeMap(() => {
            return [ApplicationActions.createEditApplicationPermissionSuccess({
              payload: {
                appId
              }
            })];
          }),
          catchError((response) => {
            return [ApplicationActions.createEditApplicationPermissionFailure({
              payload: { error: { ...response.error, params: permissionName } }
            })];
          })
        );
      })
    )
  );
}
