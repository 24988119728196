/* angular */
import { createReducer, on } from '@ngrx/store';

/* services */

/* actions */
import * as GroupPolicyDataStoreActions from './group-policy-data-store.actions';
import { initialState } from './group-policy-data-store.interfaces';

/* state key */
export const featureKey = 'group-policy';


export const reducer = createReducer(
  initialState,
  /* reducer functions for actions */
  on(GroupPolicyDataStoreActions.initGroupPolicy, (state, action) => ({
    ...state,
    loading: true
  })),
  on(GroupPolicyDataStoreActions.getGroupPolicyListSuccessfully, (state, action) => ({
    ...state,
    groupPolicyList: action.payload.groupPolicyList
  })),

);
