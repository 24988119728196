import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductDataStoreModule } from './products/product-data-store.module';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AppDataStoreModule } from './app/app-data-store.module';
import { ApplicationDataStoreModule } from './applications/application-data-store.module';
import { GroupPolicyDataStoreModule } from './group-policy/group-policy-data-store.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ProductDataStoreModule,
    AppDataStoreModule,
    ApplicationDataStoreModule,
    GroupPolicyDataStoreModule
  ],
  exports: [
    StoreModule,
    EffectsModule
  ]
})
export class DataStoreModule { }
