/* angular */
import { createReducer, on } from '@ngrx/store';
import { initialState } from './app-data-store.interfaces';

/* services */

/* actions */
import * as AppDataStoreActions from './app-data-store.actions';

/* state key */
export const featureKey = 'app';

export const reducer = createReducer(
    initialState,
    /* reducer functions for actions */
    on(AppDataStoreActions.initApp, (state, action) => ({
        ...state,
        initialized: true
    }))
);
