import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { CreateEditDialogComponent } from '../create-edit-permission-dialog/create-edit-permission-dialog.component';

@Component({
  selector: 'app-delete-permission-dialog',
  templateUrl: './delete-permission-dialog.component.html',
  styleUrls: ['./delete-permission-dialog.component.scss']
})
export class DeletePermissionDialogComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CreateEditDialogComponent>,
    public store: Store<any>,
  ) { }

  onCancel() {
    this.dialogRef.close();
  }

  onOk() {
    this.dialogRef.close(true);
  }
}
