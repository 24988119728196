import { Application, Product } from "@app/core/models/shared.model";

export interface GetProductApplicationsPayload {
  productId: string;
  params: any
}

export interface GetProductApplicationsSuccessPayload {
  applications: Application[],
  totalApplications: number
}


/* state interface */
export interface ProductState {
  products: Product[];
  productDetail: {
    product: Product | null;
    applications: Application[];
    totalApplications: number;
  };
  productCreated: Product | null;
  productCount: number;
  loading: boolean | null;
  productLoadingState?: boolean | null;
  error?: any;
}

/* initial values */
export const initialState: ProductState = {
  products: [],
  productDetail: {
    product: {
      name: '',
      description: '',
      type: '',
      sku: ''
    },
    applications: [],
    totalApplications: 0
  },
  productCreated: {
    name: '',
    description: '',
    type: '',
    sku: ''
  },
  productCount: 0,
  loading: null,
  productLoadingState: null,
  error: null
};