import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable, of} from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export abstract class EntityApiBaseService {
  private readonly perPage = 500;
  constructor(
    public http: HttpClient
  ) { }

  getData(url: string, params: any = null, responseType: any = { observe: 'response' }): Observable<any> {
    if (params) {
      const httpParams: HttpParams = new HttpParams({ fromObject: params as any });
      const options = Object.assign({ params: httpParams }, responseType);
      return this.http.get(url, options);
    }
    return this.http.get(url, responseType);
  }

  getAll(url: string, params: any = null) {
    const firstPageParams: HttpParams = new HttpParams({ fromObject: { ...params, page: 1, per_page: this.perPage } });
    const firstPage = this.http.get(url, {
      params: firstPageParams, observe: 'response'
    });

    return firstPage.pipe(
      switchMap((res: any) => {
        const pageCount = +res.headers.get('x-page-count');
        const remainingPages = [];
        for (let i = 2; i <= pageCount; i++) {
          const nextPageParams: HttpParams = new HttpParams({ fromObject: { ...params, page: i, per_page: this.perPage } });
          const nextPage = this.http.get(url,
            { params: nextPageParams, observe: 'response' });
          remainingPages.push(nextPage);
        }
        return forkJoin([of(res), ...remainingPages]) as Observable<Array<HttpResponse<any>>>;
      }),
      map((allResponses) => {
        return allResponses.reduce((acc: any, res: any) => [...acc, ...res.body], []);
      }));
  }
}
