import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent {
  @Input() searchFormControl = new FormControl('');
  @Input() placeHolder = '';
  @Output() resetSearch = new EventEmitter<void>();

  constructor() { }

  resetSearchBox() {
    this.searchFormControl.setValue('');
    this.resetSearch.emit();
  }

}
