<ol class="breadcrumb-container">
  <ng-container *ngFor="let breadcrumb of breadcrumbList; let i = index">
    <li>
      <a [class.active]="i === breadcrumbList.length - 1"
        class="breadcrumb-router"
        [routerLink]="breadcrumb.url">
        {{ breadcrumb.label }}
      </a>
    </li>
    <ng-container *ngIf="breadcrumbList.length > 1 && i !== breadcrumbList.length - 1">
      <mat-icon class="color-arrow-icon none-cursor-icon">arrow_forward</mat-icon>
    </ng-container>
  </ng-container>
</ol>
