import { Component, Input } from '@angular/core';
import { DetailListStyle, DetailRow } from 'src/app/core/models/detail-list.model';
@Component({
  selector: 'app-detail-list',
  templateUrl: './detail-list.component.html',
  styleUrls: ['./detail-list.component.scss']
})
export class DetailListComponent {
  @Input() detailList!: DetailRow[];

  rowHeight = DetailListStyle.RowHeight;
  colAmount = DetailListStyle.ColAmount;

  constructor() { }

}
