import { HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Application } from '@app/core/models/shared.model';
import { environment as env } from '@environments/environment';
import { Observable } from 'rxjs';
import { EntityApiBaseService } from './entity-api-base.service';

@Injectable({
  providedIn: 'root'
})
export class ApplicationApiService extends EntityApiBaseService {

  private apiUrl = env.coreEntityApiBase.url;
  private APPLICATION_RESOURCE = 'applications';
  private PRODUCT_RESOURCE = 'products';
  private ROLE_RESOURCE = 'roles';
  private PERMISSION_RESOURCE = 'permissions';

  getApplicationList(params: any): Observable<any> {
    const url = `${this.apiUrl}/${this.APPLICATION_RESOURCE}`;
    return this.getData(url, params);
  }

  getApplicationById(id: string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/${this.APPLICATION_RESOURCE}/${id}`;
    return this.getData(url);
  }

  getRoleById(appId: string, roleId: string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/${this.APPLICATION_RESOURCE}/${appId}/${this.ROLE_RESOURCE}/${roleId}`;
    return this.getData(url);
  }

  getRoleListByAppId(id: string, params: any): Observable<any> {
    const url = `${this.apiUrl}/${this.APPLICATION_RESOURCE}/${id}/${this.ROLE_RESOURCE}`;
    return this.getData(url, params);
  }

  getAllRolesByAppId(id: string, params: any): Observable<any> {
    const url = `${this.apiUrl}/${this.APPLICATION_RESOURCE}/${id}/${this.ROLE_RESOURCE}`;
    return this.getAll(url, params);
  }

  getPermissionListByAppId(id: string, params: any): Observable<any> {
    const url = `${this.apiUrl}/${this.APPLICATION_RESOURCE}/${id}/permissions`;
    return this.getData(url, params);
  }

  updateRolePermissions(appId: string, roleId: string, params: any) {
    const url = `${this.apiUrl}/${this.APPLICATION_RESOURCE}/${appId}/${this.ROLE_RESOURCE}/${roleId}`;
    return this.http.patch(url, params, { observe: 'response' });
  }

  editApplicationPermission(appId: string, permId: string, params: any) {
    const url = `${this.apiUrl}/${this.APPLICATION_RESOURCE}/${appId}/${this.PERMISSION_RESOURCE}/${permId}`;
    return this.http.patch(url, params, { observe: 'response' });
  }

  createApplicationPermission(appId: string, params: any) {
    const url = `${this.apiUrl}/${this.APPLICATION_RESOURCE}/${appId}/${this.PERMISSION_RESOURCE}`;
    return this.http.post(url, params, { observe: 'response' });
  }

  // TODO: update the actual API once available.
  deleteApplicationPermission(appId: string, permId: string) {
    const url = `${this.apiUrl}/${this.APPLICATION_RESOURCE}/${appId}/permissions`;
    return this.getData(url);
  }

  editApplication(appId: string, params: any): Observable<any> {
    const url = `${this.apiUrl}/${this.APPLICATION_RESOURCE}/${appId}/${this.PRODUCT_RESOURCE}`;
    return this.http.patch(url, params, { observe: 'response' });
  }

  createApplication(params: Partial<Application>): Observable<any> {
    const url = `${this.apiUrl}/${this.APPLICATION_RESOURCE}/`;
    return this.http.post(url, params, { observe: 'response' });
  }

  editApplicationWithoutProduct(appId: string, params: any): Observable<any> {
    const url = `${this.apiUrl}/${this.APPLICATION_RESOURCE}/${appId}`;
    return this.http.patch(url, params, { observe: 'response' });
  }

  createRole(appId: string, params: any): Observable<any> {
    const url = `${this.apiUrl}/${this.APPLICATION_RESOURCE}/${appId}/${this.ROLE_RESOURCE}`;
    return this.http.post(url, params, { observe: 'response' });
  }

  editRole(appId: string, roleId: string, params: any): Observable<any> {
    const url = `${this.apiUrl}/${this.APPLICATION_RESOURCE}/${appId}/${this.ROLE_RESOURCE}/${roleId}`;
    return this.http.patch(url, params, { observe: 'response' });
  }
}
