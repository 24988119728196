import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { concatMap, map, switchMap } from 'rxjs/operators';
import { IBreadCrumb } from '@app/modules/zonar-ui-breadcrumb/zonar-ui-breadcrumb.model';
import { ActivatedRoute } from '@angular/router';
import { VALIDATION_PATTERNS } from '../consts/common.const';
import { ProductApiService } from './api/product-api.service';
import { ApplicationApiService } from './api/application-api.service';
import { toTitleCase } from '../utils';

@Injectable({
  providedIn: 'root'
})
export class BreadCrumbService {
  private sourceReg = VALIDATION_PATTERNS.UUID.source;
  private productIdRegExp = new RegExp(`product${this.sourceReg}`);
  private applicationIdRegExp = new RegExp(`application${this.sourceReg}`);
  private roleIdRegExp = new RegExp(`application${this.sourceReg.slice(0, this.sourceReg.length - 1)}/role${this.sourceReg}`);
  private homePage!: IBreadCrumb;

  private ROUTE_DATA_BREADCRUMB = 'breadcrumb';

  constructor(
    private productApiService: ProductApiService,
    private applicationApiService: ApplicationApiService
  ) { }

  getLabelName(item: IBreadCrumb): Observable<string> {
    if (item.url.match(this.productIdRegExp)) {
      return this.productApiService.getProductById(item.params?.['id']).pipe(map(res => res.body.name));
    }

    if (item.url.match(this.applicationIdRegExp)) {
       return this.applicationApiService.getApplicationById(item.params?.['id']).pipe(
         map(val => val.body.name)
       );
    }

    if (item.url.match(this.roleIdRegExp)) {
      return this.applicationApiService.getRoleById(item.params?.['id'], item.params?.['roleId']).pipe(
        map(val => val.body.name)
      );
    }
    return of(item.label);
  }

  getHomePage() {
    return this.homePage;
  }

  prepareDataBreadCrumb(route: any) {
    const rootUrl = route.snapshot['_routerState']?.url?.split('/')[1];
    if (rootUrl) {
      this.homePage = {
        label: toTitleCase(`${rootUrl.split('-').join(' ')} list`),
        url: `/${rootUrl}`,
        params: {}
      };
    }
    return of({});
  }

  createBreadcrumbs(route: ActivatedRoute, url: string = '', breadcrumbs: IBreadCrumb[] = []): any {
    const children: ActivatedRoute[] = route.children;

    if (children.length === 0) {
      return breadcrumbs;
    }

    for (const child of children) {
      const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');
      if (routeURL !== '') {
        url += `/${routeURL}`;
      }

      const label = child.snapshot.data[this.ROUTE_DATA_BREADCRUMB];
      const { params } = child.snapshot;
      if (label) {
        breadcrumbs.push({ label, url, params });
      }

      return this.createBreadcrumbs(child, url, breadcrumbs);
    }
  }

  removeDuplicateUrl(breadCrumbList: IBreadCrumb[]): IBreadCrumb[] {
    return breadCrumbList.filter((value: IBreadCrumb, index: number, array: IBreadCrumb[]) =>
      array.findIndex((currentValue) => currentValue.url === value.url || currentValue.label === value.label) === index);
  }

  buildBreadCrumb(breadcrumbList: IBreadCrumb[]): Observable<IBreadCrumb[]> {
    return forkJoin(
      breadcrumbList.map(breadcrumb =>
        this.getLabelName(breadcrumb).pipe(
          map((labelName) => {
            return {
              ...breadcrumb,
              label: labelName
            };
          })
        )));
  }

  customPipeBreadCrumb(route: ActivatedRoute) {
    return of({}).pipe(
      switchMap(() => this.prepareDataBreadCrumb(route.root)),
      switchMap(() => of(this.removeDuplicateUrl(this.createBreadcrumbs(route.root)))),
      concatMap((breadCrumbList: IBreadCrumb[]) => this.buildBreadCrumb(breadCrumbList)));
  }

}
