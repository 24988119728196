export const environment = {
  name: 'development',
  authEnv: 'development',
  region: 'NA',
  appUrl: 'https://product-configuration.dev.zonarsystems.net',
  production: false,
  coreEntityApiBase: {
    url: `https://api-dev.zonarsystems.net/core/v1`,
    urlCVIConfig: `https://zonar-nonprod-dev.apigee.net/evir/v1`
  },
  auth: {
    clientID: 'VbzcUADPgTA3ELGQB8FnwPwF28qwzPNh',
    domain: 'zonar-dev.auth0.com',
    audience: 'http://apidev.zonarsystems.net/',
    applicationId: '90c3a106-2b2d-460b-a4d4-27ba2f6c8fd6',
  },
  sidenavHelpUrl: 'https://zonarsystemshelp.zendesk.com/hc/en-us',
  datadog: {
    applicationId: 'ba5789b1-23da-4910-be0f-595f35a06e73',
    clientToken: 'pub59d2d154a75d7a7a15ecf1551517897c',
    site: 'us5.datadoghq.com',
    tagEnvironment: 'development'
  },
  i18n: {
    supportedLanguages: ['en', 'en-US', 'de', 'fr-FR', 'es-ES', 'it'],
    defaultLanguage: 'en-US',
  },
};