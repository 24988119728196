import { Component, Input, OnInit } from '@angular/core';
import { GuidanceContentKey } from '@app/shared/enums/guidance-content';



@Component({
  selector: 'app-guidance',
  templateUrl: './guidance.component.html',
  styleUrls: ['./guidance.component.scss']
})
export class GuidanceComponent {
  @Input() contentKeys: string[] = [];

  ContentKeys = GuidanceContentKey;

  constructor() { }

}
