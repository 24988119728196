<div class="transfer-dialog" fxLayout="column" fxLayoutAlign="space-between start">
  <div fxLayout="row" fxLayoutAlign="center center" class="title">
    <span>{{data?.title}}</span>
  </div>

  <app-transfer-list class="transfer-comp"
    [headerLabels]="data?.headerLabels"
    [options]="data?.transferOptions"
    (selectedEmit)="updateSelected($event)"
  >
  </app-transfer-list>

  <div class="dialog-button" fxLayout="row" fxLayoutAlign="end end">
    <button mat-stroked-button type="button" fxLayoutAlign="center center" (click)="onCancel()">
      Cancel</button>
    <button [disabled]="!updatedSelections" mat-flat-button type="submit" fxLayoutAlign="center center" class="button-ml action-button"
      (click)="onConfirm()">Save</button>
  </div>
</div>
