<div class="create-edit-role-container" fxLayout="column" fxLayoutAlign="space-between start">
  <h1 fxFlex="5" class="page-title">{{dialogMode === componentViewMode.EDIT ? 'Edit Role' : 'Create Role'}}</h1>
  <div class="warning-container" *ngIf="dialogMode === componentViewMode.EDIT">
    <mat-icon fxFlex="column wrap inline" fxLayoutAlign="center center" class="warning-icon">
      info_outline
    </mat-icon>
    <span class="warning-message">Changing the role name might break the ECLs. Please discuss it with your team before changing a role name.</span>
  </div>
  <form [formGroup]="roleFormGroup" fxLayout="column" class="form-container" fxLayoutGap="0.75rem">
    <mat-form-field appearance="outline">
      <mat-label>Name</mat-label>
      <input matInput formControlName="name">
      <mat-error *ngIf="roleFormGroup.controls.name.hasError('required')">
        This field is required.
      </mat-error>
      <mat-error *ngIf="roleFormGroup.controls.name.hasError('duplicated')">
        This role name is duplicated.
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Description</mat-label>
      <textarea matInput formControlName="description" [rows]="defaultRowsDescription" class="input-field-description"></textarea>
    </mat-form-field>
  </form>
  <div class="button-container" fxLayout="row" fxLayoutAlign="end end">
    <button mat-raised-button type="submit" (click)="onCloseDialog()">Cancel</button>
    <button mat-flat-button class="button-ml action-button" type="submit" [disabled]="isSaveButtonDisabled()" (click)="onSave()">Save</button>
  </div>
</div>
