import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

/* reducer */
import * as fromProductsDataStore from './product-data-store.reducer';

/* effects */
import { ProductDataStoreEffects } from './product-data-store.effects';
import { initialState } from './product-data-store.interfaces';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromProductsDataStore.featureKey,
      fromProductsDataStore.reducer,
      { initialState: initialState }
    ),
    EffectsModule.forFeature([ProductDataStoreEffects])
  ],
  exports: [
    StoreModule,
    EffectsModule
]
})
export class ProductDataStoreModule { }
