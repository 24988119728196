import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

/* reducer */
import * as fromApplicationDataStore from './group-policy-data-store.reducer';

/* effects */
import { GroupPolicyDataStoreEffects } from './group-policy-data-store.effects';
import { initialState } from './group-policy-data-store.interfaces';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromApplicationDataStore.featureKey,
      fromApplicationDataStore.reducer,
      { initialState: initialState }
    ),
    EffectsModule.forFeature([GroupPolicyDataStoreEffects])
  ],
  exports: [
    StoreModule,
    EffectsModule
]
})
export class GroupPolicyDataStoreModule { }
